const urlBase = 'safetextnow.com';
module.exports = {
    urlApi: `https://${urlBase}/api`,
    homePage: `https://${urlBase}/#/`,
    forgotPw: `https://${urlBase}/password/reset`,
    rxInfoMapFields: `https://${urlBase}/images/rxinfo-direction.jpg`,
    defaultAvatar: `https://${urlBase}/images/no-avatar.png`,
    reloadRxinfoList: null,
    reloadUserList: null,
};

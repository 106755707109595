import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
  } from 'reactstrap';
  
class Loading extends Component {
    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <i className="fa fa-circle-o-notch fa-spin" />
                    </Col>
                </Row>
                </Container>
            </div>
        );
    }
}

export default Loading;

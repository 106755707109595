import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';

// Redux & Language
import { connect } from 'react-redux';
import { updateUser } from '../../../redux/actionCreator';
import { t } from '../../../helpers/lang';
import global from '../../../components/global';

import get from '../../../apis/get';
import post from '../../../apis/post';
import setToken from '../../../apis/setToken';
import getToken from '../../../apis/getToken';
import removeToken from '../../../apis/removeToken';

import Loading from '../../../components/Loading';
import Main from '../../../containers/Main';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      wrongInfo: false,
      needLogin: false,
      user: this.props.user,
      inactive: false,
      loading: true
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const token = await getToken();
    if (this.props.user && this.props.user.id && this.props.user.token && this.props.user.sys !== 0) {
      this.setState({ needLogin: false, loading: false });
    } else if (token) {

      try {
        const data = await get('user', token, '');
        if (data.id && data.sys !== 0) {
          data.token = token;
          this.props.updateUser(data);

          // setTimeout(function() {
          //   this.setState({ needLogin: false, loading: false });
          // }.bind(this), 1000)

          const wait = setInterval(() => {
            if (this.props.user !== null && this.props.user.sys !== 0) {
              this.setState({ needLogin: false, loading: false });
              clearInterval(wait);
            }
          }, 200);
        }
        else {
          removeToken();
          this.setState({ needLogin: true, loading: false });
        }
      }
      catch (e) {
        removeToken();
        this.setState({ needLogin: true, loadingState: false });
      }
    } else {
      this.setState({ needLogin: true, loading: false });
    }
  }
  

  async onSignIn() {
    const { email, password } = this.state;
    if (!email || !password) {
        this.setState({
          wrongInfo: true,
          inactive: false,
        });
    } else {
      this.setState({
        loading: true
      }, async () => {
        const res = await post('login','',{email, password});
        if (res && res.access_token && res.user && res.user.sys !== 0) {
          await setToken(res.access_token);
          res.user.token = res.access_token;
          this.props.updateUser(res.user);

          setToken(res.access_token).then(() => {
            const wait = setInterval(() => {
              if (this.props.user !== null && this.props.user.sys !== 0) {
                this.setState({ needLogin: false, loading: false, wrongInfo: false });
                clearInterval(wait);
              }
            }, 200);

            // this.setState({
            //   needLogin: false,
            //   wrongInfo: false,
            //   loading: false
            // });
          });
          
        } else if (res && res.message && res.message === 'account_inactive') {
          this.setState({
            needLogin: true,
            wrongInfo: true,
            inactive: true,
            loading: false
          });
        } else {
          this.setState({
            needLogin: true,
            wrongInfo: true,
            loading: false
          });
        }
      });
      


    }
    
  }

  render() {
    const { needLogin, wrongInfo, inactive, loading } = this.state;
    const signInText = wrongInfo ? (
      <Alert color="danger">
        {inactive ? t('Your account is inactive. Please check your email and active your account or contact your Administrator to resend activation email.') : t('wrong_login_info_u')}
      </Alert>
    ) : (
      <p className="text-muted">{ t('Sign in to Safe Text Now Admin by System Account.') }</p>
    );

    const login = (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.onSignIn.bind(this)}>
                      <h1>{t('Login')}</h1>
                      {signInText}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Email" autoComplete="username" onChange={text => this.setState({ email: text.target.value })}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" autoComplete="current-password" onChange={text => this.setState({ password: text.target.value })}/>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button type="submit" color="primary" className="px-4" onClick={()=>this.onSignIn()}>{t('Login')}</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <a href={global.forgotPw} className="px-0">{t('Forgot password?')}</a>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
    // return needLogin ? login : <Main />;
    return loading ? <Loading /> : (needLogin ? login : <Main />);
  }
}
function mapStateToProps(state) {
  return {
      user: state.user
  };
}

export default connect(
  mapStateToProps, {
    updateUser
  }
)(Login);

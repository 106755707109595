export function setLocalIp(ip) {
    return { type: 'SET_LOCAL_IP', ip };
}
export function updateUser(user) {
    return { type: 'UPDATE_USER', user };
}
export function updateAccount(account) {
    return { type: 'UPDATE_ACCOUNT', account };
}
export function updateUserAsync(user) {
    return { type: 'UPDATE_USER_ASYNC', user };
}


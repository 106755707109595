import global from '../components/global';

const get = async (model, token, customQr='') => {     //eslint-disable-line
    const url = `${global.urlApi}/${model}${customQr}`;    //eslint-disable-line
    const res = await fetch(url, {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
        }
    });
    return await res.json();
};

export default get;
import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import '../App.scss';
import { connect } from 'react-redux';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./DefaultLayout'));

// Pages

class Main extends Component {
  componentWillMount(){
  }

  render() {

    const mainlayout = (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
    
    return mainlayout;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    setting: state.setting
  };
}

export default connect(
  mapStateToProps
)(Main);

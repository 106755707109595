const defaultState = {
    status: 200,
    ip: null
};
const settingReducer = (state = defaultState, action) => {
    if (action.type === 'SET_LOCAL_IP') return { ...state, ip: action.ip };
    return state;
};

export default settingReducer;

import global from '../components/global';

const post = async (model, token, item = {}) => (
    await fetch(`${global.urlApi}/${model}`,     //eslint-disable-line
    {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    .then(res => res.json())
);

export default post;

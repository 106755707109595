import { put, takeEvery, all } from 'redux-saga/effects'

const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* updateUserAsync(user) {
  yield delay(500)
  yield put({ type: 'UPDATE_USER', user })
}

function* watchUpdateUserAsync() {
  yield takeEvery('UPDATE_USER_ASYNC', updateUserAsync)
}

function* helloSaga() {
//   console.log('Hello Sagas!')
}

export default function* rootSaga() {
  yield all([
    helloSaga(),
    watchUpdateUserAsync()
  ])
}
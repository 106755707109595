const setToken = async (token) => {
    try {
        await localStorage.setItem('token', token);
        return 'done';
    } catch (e) {
        return e;
    }
};

export default setToken;

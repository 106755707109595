import React, { Component } from 'react';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';


import './App.scss';
import Login from './views/Pages/Login/Login';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Login />
      </Provider>
    );
  }
}

export default App;

import store from '../redux/store';
import language from '../persistences/global.language.json';

export function t(code) {
    if (language[code]) {
        const state = store.getState();
        if (state.lang === 'en') {
            return language[code][0];
        } else if (state.lang === 'vi') {
            return language[code][1];
        }
        return language[code][0];
    }
    return code;
}

import { combineReducers } from 'redux';
import settingReducer from './settingReducer';
import userReducer from './userReducer';
import accountReducer from './accountReducer';
import langReducer from './langReducer';

const reducer = combineReducers({
    setting: settingReducer,
    user: userReducer,
    account: accountReducer,
    lang: langReducer,
});

export default reducer;
